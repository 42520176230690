import { JsonObject } from '@eagle/common';
import { AccountType, CreateAccountFromTemplateRequest, SharedThing, StakeholderAccountResult } from '@eagle/core-data-types';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../../auth';
import { EntityTypes, useEntityDisplay, useFetchAllCache, usePromise } from '../../../hooks';
import { CacheDataTypes } from '../../../types';
import { isValueObject } from '../../../util';
import { ErrorMessage } from '../../error-message';
import { MiddleSpinner } from '../../middle-spinner';
import { SelectionCheckBox } from '../../selection-check-box';
import { DataRow } from '../../static-data';
import { StakeHolderAccountCard } from './stage-confirm-dialog-card';
import { useStageConfirmDialogContext } from './stage-confirm-dialog-context';
import { StakeholderCardBase } from './stakeholder-card-base';

interface DetailRowProps {
  propertyKey: string;
  label: string;
  sharedThing: SharedThing;
}

const DetailRow: FC<DetailRowProps> = ({ propertyKey, label, sharedThing }) => {
  const { t } = useTranslation(['common']);
  const isReference = ['brand', 'country', 'model', 'variant'].includes(propertyKey);
  const rawValue = sharedThing.properties[propertyKey] as JsonObject | string | undefined;
  const entityId = isValueObject(rawValue) ? String(rawValue.value) : String(rawValue);

  const { display, loading } = useEntityDisplay(
    isReference ? (propertyKey as EntityTypes) : 'brand',
    isReference ? entityId : undefined,
  );

  const value = isReference
    ? (loading ? t('common:common.labels.loading') : display)
    : entityId;

  const displayValue = !value || isEmpty(value)
    ? t('common:component.shared-thing-card.hint.no-value')
    : value;

  return (
    <DataRow
      key={propertyKey}
      label={label}
      value={displayValue}
      data-testid={`property-display-${propertyKey}`}
    />
  );
};

const referencePropertyKeys = ['variant', 'model', 'country'] as const;

const VehicleDetailsSection: FC<{ sharedThing: SharedThing }> = ({ sharedThing }) => {
  const { t } = useTranslation(['common']);

  const vehicleProperties = Object.entries(sharedThing.properties).map(
    ([key]) => {
      const result: { key: string; label: string; isReference?: boolean } = {
        key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
      };
      if (referencePropertyKeys.includes(key as typeof referencePropertyKeys[number])) {
        result.isReference = true;
      }
      return result;
    },
  );

  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        {t('common:page.thing-detail.title')}
      </Typography>
      <Card sx={{ bgcolor: 'grey.100' }}>
        <CardContent sx={{ p: 1 }}>
          <Stack spacing={1}>
            {vehicleProperties.map(({ key, label }) => (
              <DetailRow
                key={key}
                propertyKey={key}
                label={label}
                sharedThing={sharedThing}
              />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export const StageConfirmDialogConfirmStep: FC = () => {
  const { state, stage, thing, dispatch, lifecycleTemplate } = useStageConfirmDialogContext();
  const { t } = useTranslation(['common', 'admin']);

  const sortedStakeholders = useMemo(() => {
    if (state.config?.skipStakeholderSelection) return [];

    const targetStage = lifecycleTemplate.stages.find((s) => s.stageId === stage.stageId);
    const requiredStakeholders = targetStage?.enterConditions?.requiredStakeholders || [];

    return state.stakeholders
      .filter((stakeholder) => {
        if (state.config?.isSelfClaim) {
          return stakeholder.accountId && !stakeholder.initialAccountId;
        }
        return requiredStakeholders.includes(stakeholder.role) || stakeholder.accountId;
      })
      .sort((a, b) => {
        // Sort by required first, then by those with accountId
        if (a.isRequired !== b.isRequired) return a.isRequired ? -1 : 1;
        if (Boolean(a.accountId) !== Boolean(b.accountId)) return a.accountId ? -1 : 1;
        return 0;
      });
  }, [state.stakeholders, state.config, lifecycleTemplate, stage.stageId]);

  const handleClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_CONFIRMATION', value: e.target.checked });
  }, [dispatch]);

  const confirmationText = state.config?.isSelfClaim
    ? t('common:component.self-claim.confirm-text', {
      stageDisplay: stage.display,
      thingDisplay: thing?.display,
    })
    : t('admin:page.thing-detail.update-stage.selection-confirmed.labels');

  const updateStageText = t('admin:page.thing-detail.update-stage.confirm-selection.labels', {
    thingDisplay: thing?.display,
    stageName: stage.display,
  });

  return (
    <Stack spacing={2}>
      {state.config?.isSelfClaim && state.config.selectedSharedThing && (
        <VehicleDetailsSection sharedThing={state.config.selectedSharedThing} />
      )}

      {(!state.config?.isSelfClaim || !state.config?.skipStakeholderSelection) && (
        <Stack spacing={2}>
          {sortedStakeholders.map((stakeholder) => (
            stakeholder.isCreatingNewAccount && stakeholder.createAccount ? (
              <StakeholderItem
                key={stakeholder.role}
                stakeholderData={stakeholder.createAccount.data}
                stakeholderRole={stakeholder.role}
              />
            ) : stakeholder.accountId ? (
              <StakeholderConfirmCard
                key={stakeholder.role}
                role={stakeholder.role}
                accountId={stakeholder.accountId}
              />
            ) : null
          ))}
        </Stack>
      )}

      {!state.config?.isSelfClaim && (
        <Typography>
          {updateStageText}
        </Typography>
      )}

      <SelectionCheckBox
        checked={state.confirmationChecked}
        handleClick={handleClick}
        label={confirmationText}
        data-testid="stage-dialog-confirm-checkbox"
        sx={{ pl: 0 }}
      />
    </Stack>
  );
};

interface StakeholderConfirmCardProps {
  accountId: string;
  role: string;
}

const StakeholderConfirmCard: FC<StakeholderConfirmCardProps> = ({ accountId, role }) => {
  const { sharedThingId, state } = useStageConfirmDialogContext();
  const { restClient } = useAuthenticated();

  const [account, accountError, accountStatus] = usePromise<StakeholderAccountResult>(
    async () => {
      return restClient.sharedThing.getStakeholderAccount(sharedThingId, role, accountId);
    },
    [sharedThingId, role, accountId, restClient],
  );

  const [accountType, accountTypeError, accountTypeStatus] = usePromise(
    async () => {
      return restClient.sharedThing.getStakeholderAccountType(sharedThingId, role, accountId);
    },
    [sharedThingId, role, accountId, restClient],
  );

  if (accountStatus === 'pending' || accountTypeStatus === 'pending') {
    return <MiddleSpinner />;
  }

  if (accountError || accountTypeError) {
    return <ErrorMessage error={accountError || accountTypeError || new Error('Unknown error')} />;
  }

  return (
    <StakeholderCardBase hideRole={state.isSimplifiedAccountSelect} role={role}>
      <StakeHolderAccountCard
        accountType={accountType}
        display={account.display}
        properties={account.properties as JsonObject}
        role={role}
        initialUsers={null}
      />
    </StakeholderCardBase>
  );
};

interface StakeholderItemProps {
  stakeholderData: CreateAccountFromTemplateRequest;
  stakeholderRole: string;
}

const StakeholderItem: FC<StakeholderItemProps> = ({ stakeholderData, stakeholderRole }) => {
  const accountTypesCache = useFetchAllCache(CacheDataTypes.ACCOUNT_TYPE);
  const [accountType, accountTypeError, accountTypeStatus] = usePromise(
    () => accountTypesCache.one<AccountType>(stakeholderData.accountTypeId),
    [accountTypesCache, stakeholderData.accountTypeId],
  );

  if (accountTypeStatus === 'pending') return <MiddleSpinner />;
  if (accountTypeError) return <ErrorMessage error={accountTypeError} />;

  return (
    <StakeholderCardBase role={stakeholderRole}>
      <StakeHolderAccountCard
        accountType={accountType}
        display={stakeholderData.display}
        properties={stakeholderData.properties}
        role={stakeholderRole}
        initialUsers={stakeholderData.users}
      />
    </StakeholderCardBase>
  );
};
