import { Json } from '@eagle/common';
import { Account, AccountTemplate, CreateAccountFromTemplateRequest, LifecycleTemplate, SharedThing, Stage, ThingLifeCycleStateResponse } from '@eagle/core-data-types';
import { ReactNode, Reducer } from 'react';
import { Nullable } from '../../../types';
import { UserBasicType } from '../../multiple-user-basic-editor';
import { canSetStakeholder } from '../life-cycle.utils';

export interface StakeholderState {
  account?: Nullable<Account>;
  accountId?: Nullable<string>;
  accountTemplateIds: string[];
  allowAccountRequests: boolean;
  canEdit: boolean;
  createAccount?: {
    accountTemplateId: string;
    data: CreateAccountFromTemplateRequest;
    createdAccountId?: string;
    isCustomHomeDomain: boolean;
  };
  initialAccountId?: string;
  isEditing: boolean;
  isDirty: boolean;
  isCreatingNewAccount: boolean;
  isRemoveConfirmationChecked: boolean;
  isRequired: boolean;
  lastAccountSearch?: string;
  role: string;
  config?: Omit<StakeholderConfig, 'removeConfirmation'>;
}

interface RemoveConfirmation {
  checkboxLabel: string;
  confirmText: string;
}

interface StakeholderConfig {
  removeConfirmation?: RemoveConfirmation;
  requiredToChange?: boolean;
  selectPlaceholder?: string;
}

export interface StageConfirmDialogConfig {
  renderConfirmation?: (state: StageConfirmDialogState) => ReactNode;
  stakeholderRolesToDisplay?: string[];
  simplifiedAccountSelect?: boolean;
  stakeholders?: Record<string, StakeholderConfig>;
  successMessage?: string;
  updateStageLabel?: string;
  isSelfClaim?: boolean;
  selectedSharedThing?: SharedThing;
  skipStakeholderSelection?: boolean;
  forceEditableStakeholderSelect?: boolean;
}

export enum StageConfirmDialogStep {
  edit = 0,
  confirm = 1,
  confirmRemove = 2,
}

export interface StageConfirmDialogState {
  stakeholders: StakeholderState[];
  confirmationChecked: boolean;
  isSubmitting: boolean;
  removeConfirmation?: RemoveConfirmation;
  config?: StageConfirmDialogConfig;
  currentStep: StageConfirmDialogStep;
  isSimplifiedAccountSelect: boolean;
  renderConfirmation?: (state: StageConfirmDialogState) => ReactNode;
}

export type StageConfirmDialogAction =
  { type: 'SET_STEP'; step: StageConfirmDialogStep } |
  { type: 'SET_ACCOUNT'; role: string; account: Nullable<Account>; accountId: Nullable<string> } |
  { type: 'START_SUBMIT' } |
  { type: 'SUBMIT_FINISHED' } |
  { type: 'SET_CONFIRMATION'; value: boolean } |
  { type: 'START_STAKEHOLDER_EDIT_MODE'; role: string } |
  { type: 'CANCEL_STAKEHOLDER_EDIT_MODE'; role: string } |
  { type: 'FINISH_STAKEHOLDER_EDIT_MODE'; role: string } |
  { type: 'SET_LAST_ACCOUNT_SEARCH'; role: string; search: string } |
  { type: 'START_STAKEHOLDER_CREATE_MODE'; role: string; accountTemplates: AccountTemplate[]; account: Account } |
  { type: 'CANCEL_STAKEHOLDER_CREATE_MODE'; role: string } |
  { type: 'SET_ACCOUNT_TEMPLATE'; role: string; accountTemplate: AccountTemplate; account: Account } |
  { type: 'SET_ACCOUNT_TYPE'; role: string; accountTypeId: string } |
  { type: 'SET_CREATE_ACCOUNT_DATA'; role: string; field: keyof CreateAccountFromTemplateRequest; value: Json | UserBasicType[] } |
  { type: 'SET_CREATED_ACCOUNT_ID'; role: string; createdAccountId: string } |
  { type: 'SET_REMOVE_CONFIRMATION'; role: string; value: boolean }

export const stageConfirmDialogReducer: Reducer<StageConfirmDialogState, StageConfirmDialogAction> = (state, action) => {
  switch (action.type) {
    case 'SET_STEP':
      return {
        ...state,
        currentStep: action.step,
      };

    case 'SET_ACCOUNT': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, account: action.account, accountId: action.accountId, isEditing: false, isDirty: true }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'START_SUBMIT':
      return {
        ...state,
        isSubmitting: true,
      };

    case 'SET_CONFIRMATION':
      return {
        ...state,
        confirmationChecked: action.value,
      };

    case 'SUBMIT_FINISHED':
      return {
        ...state,
        isSubmitting: false,
      };

    case 'START_STAKEHOLDER_EDIT_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, isEditing: true }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'CANCEL_STAKEHOLDER_EDIT_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, isEditing: false, isDirty: false, accountId: stakeholder.initialAccountId }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'FINISH_STAKEHOLDER_EDIT_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, isEditing: false }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_LAST_ACCOUNT_SEARCH': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, lastAccountSearch: action.search }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'START_STAKEHOLDER_CREATE_MODE': {
      const updatedStakeholders = updateStakeholderState(state.stakeholders, action.role, (stakeholder) => {
        if (action.accountTemplates.length === 1) {
          return {
            ...stakeholder,
            isCreatingNewAccount: true,
            isEditing: false,
            createAccount: {
              accountTemplateId: action.accountTemplates[0]._id,
              data: createInitialAccountData(action.accountTemplates[0], stakeholder.lastAccountSearch),
              isCustomHomeDomain: action.accountTemplates[0].accountDefaults.homeDomain.type === 'custom',
            },
          };
        }
        return { ...stakeholder, isCreatingNewAccount: true, isEditing: false };
      });

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'CANCEL_STAKEHOLDER_CREATE_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          const updatedStakeholder: StakeholderState = { ...stakeholder, isCreatingNewAccount: false, createAccount: undefined };
          if (stakeholder.isEditing) {
            updatedStakeholder.accountId = updatedStakeholder.initialAccountId;
            updatedStakeholder.isDirty = false;
          }

          return updatedStakeholder;
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_ACCOUNT_TEMPLATE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          return {
            ...stakeholder,
            createAccount: {
              accountTemplateId: action.accountTemplate._id,
              data: createInitialAccountData(action.accountTemplate, stakeholder.lastAccountSearch),
              isCustomHomeDomain: action.accountTemplate.accountDefaults.homeDomain.type === 'custom',
            },
          };
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_ACCOUNT_TYPE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          if (!stakeholder.createAccount) {
            return stakeholder;
          }
          return { ...stakeholder, createAccount: { ...stakeholder.createAccount, data: { ...stakeholder.createAccount.data, accountTypeId: action.accountTypeId, properties: {} } } };
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_CREATE_ACCOUNT_DATA': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          if (!stakeholder.createAccount) {
            return stakeholder;
          }
          return { ...stakeholder, createAccount: { ...stakeholder.createAccount, data: { ...stakeholder.createAccount.data, [action.field]: action.value } } };
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_CREATED_ACCOUNT_ID': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          if (stakeholder.createAccount) {
            return {
              ...stakeholder,
              createAccount: {
                ...stakeholder.createAccount,
                createdAccountId: action.createdAccountId,
              },
            };
          }
          return stakeholder;
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_REMOVE_CONFIRMATION': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, isRemoveConfirmationChecked: action.value }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    default:
      return state;
  }
};

const updateStakeholderState = (stakeholders: StakeholderState[], role: string, updates: (state: StakeholderState) => StakeholderState): StakeholderState[] => {
  const stakeholderIndex = stakeholders.findIndex((stakeholderState) => stakeholderState.role === role);
  if (stakeholderIndex === -1) {
    return stakeholders;
  }

  const updatedStakeholders = [...stakeholders];

  updatedStakeholders[stakeholderIndex] = updates(updatedStakeholders[stakeholderIndex]);

  return updatedStakeholders;
};

const determineTargetStage = (
  lifecycleTemplate: LifecycleTemplate,
  selectedSharedThing: SharedThing | undefined,
  config?: StageConfirmDialogConfig,
  stage?: Stage,
): Stage => {
  if (!config?.isSelfClaim) return stage || lifecycleTemplate.stages[0];

  if (!selectedSharedThing) return lifecycleTemplate.stages[0];

  const currentStageId = selectedSharedThing.lifecycleState?.stageId;
  const currentStage = lifecycleTemplate.stages.find((s) => s.stageId === currentStageId);
  const selfClaimTargetStageId = currentStage?.stakeholders?.dealer?.selfClaim?.targetStage;

  return selfClaimTargetStageId
    ? lifecycleTemplate.stages.find((s) => s.stageId === selfClaimTargetStageId) || lifecycleTemplate.stages[0]
    : lifecycleTemplate.stages[0];
};

export const initStageConfirmDialogReducer = ({
  lifeCycleState,
  lifecycleTemplate,
  stakeholderRoles,
  config,
  stage,
}: {
  lifeCycleState: ThingLifeCycleStateResponse;
  lifecycleTemplate: LifecycleTemplate;
  stakeholderRoles: string[];
  config?: StageConfirmDialogConfig;
  stage?: Stage;
}): StageConfirmDialogState => {
  const targetStage = determineTargetStage(
    lifecycleTemplate,
    config?.selectedSharedThing,
    config,
    stage,
  );

  const requiredStakeholders = targetStage.enterConditions?.requiredStakeholders ?? [];
  const currentUserRole = stakeholderRoles[0]?.toLowerCase();
  const isCurrentUserOnlyRequiredStakeholder =
    requiredStakeholders.length === 1 &&
    requiredStakeholders[0].toLowerCase() === currentUserRole;

  const stageStakeholderRoles = Object.keys(targetStage.stakeholders || {});
  const allStakeholderRoles = Array.from(new Set([
    ...stageStakeholderRoles,
    ...requiredStakeholders,
  ]));

  const stakeholderStates: StakeholderState[] = allStakeholderRoles
    .filter((role) => {
      if (requiredStakeholders.includes(role)) {
        return true;
      }

      if (config?.stakeholderRolesToDisplay) {
        return config.stakeholderRolesToDisplay.includes(role);
      }

      if (config?.isSelfClaim) {
        if (isCurrentUserOnlyRequiredStakeholder && role.toLowerCase() === currentUserRole) {
          return false;
        }
        if (requiredStakeholders.includes(role)) {
          return true;
        }
        return role.toLowerCase() !== currentUserRole;
      }

      return true;
    })
    .map((role) => {
      const stakeholder = targetStage.stakeholders?.[role];
      const stakeholderConfig = config?.stakeholders?.[role];

      const initialAccountId = lifeCycleState.stakeholders?.[role]?._id ??
        lifecycleTemplate.stakeholderAccounts[role]?.fixedAccountId ?? undefined;

      return {
        role,
        initialAccountId,
        allowAccountRequests: config?.simplifiedAccountSelect ? false : Boolean(stakeholder?.allowAccountRequest),
        accountTemplateIds: lifecycleTemplate.stakeholderAccounts[role]?.accountTemplateIds ?? [],
        isEditing: false,
        isDirty: false,
        accountId: initialAccountId,
        isCreatingNewAccount: false,
        isRemoveConfirmationChecked: false,
        isRequired: Boolean(targetStage.enterConditions?.requiredStakeholders?.includes(role)),
        canEdit: !lifecycleTemplate.stakeholderAccounts[role]?.fixedAccountId &&
          canSetStakeholder(role, stakeholderRoles, lifecycleTemplate),
        config: stakeholderConfig,
      };
    });

  const hasRequiredStakeholdersNeedingSelection = stakeholderStates.some(
    (s) => s.isRequired && !s.initialAccountId,
  );

  const shouldSkipToConfirm = config?.isSelfClaim && (
    !hasRequiredStakeholdersNeedingSelection || isCurrentUserOnlyRequiredStakeholder
  );

  const removeConfirmation = (() => {
    if (stakeholderStates.length === 1) {
      const role = stakeholderStates[0].role;
      const stakeholderConfig = config?.stakeholders?.[role];
      return stakeholderConfig?.removeConfirmation;
    }
    return undefined;
  })();

  const finalState = {
    stakeholders: stakeholderStates,
    confirmationChecked: false,
    isSubmitting: false,
    removeConfirmation,
    config,
    currentStep: shouldSkipToConfirm || stakeholderStates.length === 0
      ? StageConfirmDialogStep.confirm
      : StageConfirmDialogStep.edit,
    isSimplifiedAccountSelect: Boolean(config?.simplifiedAccountSelect),
    renderConfirmation: config?.renderConfirmation,
  };

  return finalState;
};

const createInitialAccountData = (accountTemplate: AccountTemplate, lastAccountSearch?: string): CreateAccountFromTemplateRequest => {
  const accountTypeId = accountTemplate.accountDefaults.accountTypeIds.length === 1 ? accountTemplate.accountDefaults.accountTypeIds[0] : null;

  return {
    accountTypeId,
    display: lastAccountSearch ?? '',
    users: [{ email: '', display: '' }],
    homeDomain: accountTemplate.accountDefaults.homeDomain.type === 'custom' ? '' : null,
    labels: accountTemplate.accountDefaults.labels,
    properties: {},
    tags: accountTemplate.accountDefaults.tags,
  };
};
