import { Time } from '@eagle/common';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, Button, Checkbox, Divider, FormControlLabel, IconButton, Alert as MuiAlert, Popover, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { DateTimePicker, DateTimePickerProps, LocalizationProvider, PickersActionBarProps, renderDigitalClockTimeView } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18n } from '../../providers';
import { Nullable, Undefinable } from '../../types';
import { CalendarIcon as DefaultCalendarIcon } from '../icons';
import { DateTimeRangeSelectProps, DateTimeRangeSelectValue } from './date-time-range-select.types';
import { StaticDateTimePicker } from './static-date-time-picker';

export const DateTimeRangeSelect: FC<DateTimeRangeSelectProps> = ({
  defaultValue,
  errorMsg,
  fromLabel,
  futureDates,
  hideInput,
  icon,
  isValid = true,
  label,
  nowLabel,
  onDateChange,
  open,
  pickerSx,
  quickSelectLabel,
  quickSelectOptions,
  showNow = true,
  smallScreen,
  sx,
  timeSteps = { minutes: 15 },
  toLabel,
  value,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const [startDate, setStartDate] = useState<Nullable<DateTime>>(defaultValue?.startDate ?? null);
  const [endDate, setEndDate] = useState<Nullable<DateTime>>(defaultValue?.endDate ?? null);
  const [isNow, setIsNow] = useState(false);
  const [dateLabel, setDateLabel] = useState<Undefinable<string>>(
    !defaultValue
      ? undefined
      : `${defaultValue.startDate.toFormat('MMM d, yyyy hh:mm a')} - ${(defaultValue.endDate || DateTime.now()).toFormat('MMM d, yyyy hh:mm a')}`,
  );
  const [resetValue, setResetValue] = useState<{
    startDate: Nullable<DateTime>;
    endDate: Nullable<DateTime>;
    isNow: boolean;
  }>({
    startDate,
    endDate,
    isNow,
  });
  const [dateValid, setDateValid] = useState<boolean>(false);
  const [dateErrorMsg, setDateErrorMsg] = useState<Undefinable<string>>(undefined);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [viewStartDateCalendar, setViewStartDateCalendar] = useState(false);
  const [viewEndDateCalendar, setViewEndDateCalendar] = useState(false);
  const previousValueRef = useRef<Undefinable<DateTimeRangeSelectValue>>();

  const CalendarIcon = useMemo(() => icon ?? DefaultCalendarIcon, [icon]);

  useEffect(() => {
    if (!value) return;

    const previousValue = previousValueRef.current;
    const previousStartDate = previousValue?.startDate?.isValid ? previousValue.startDate.toMillis() : null;
    const previousEndDate = previousValue?.endDate?.isValid ? previousValue.endDate.toMillis() : null;
    const newStartDate = value?.startDate?.isValid ? value.startDate.toMillis() : null;
    const newEndDate = value?.endDate?.isValid ? value.endDate.toMillis() : null;
    if (previousStartDate !== newStartDate || previousEndDate !== newEndDate) {
      setStartDate(value.startDate);
      setEndDate(value.endDate ?? null);
      setDateLabel(`${value.startDate.toFormat('MMM d, yyyy hh:mm a')} - ${(value.endDate || DateTime.now()).toFormat('MMM d, yyyy hh:mm a')}`);
      previousValueRef.current = value;
    }
  }, [value]);

  const handleClickButton = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    setResetValue({ startDate, endDate, isNow });
    setPickerOpen((prev) => !prev);
    if (!smallScreen) setAnchorEl(event.currentTarget);
  }, [endDate, isNow, smallScreen, startDate]);

  const handleClosePopover = (): void => {
    setPickerOpen(false);
    setAnchorEl(null);
  };

  const handleQuickSelection = useCallback((value: DateTime): void => {
    if (!value?.isValid) {
      return;
    }

    setStartDate(value);
    setIsNow(true);
  }, []);

  const cancelSelect = useCallback((): void => {
    handleClosePopover();
    setStartDate(resetValue.startDate);
    setEndDate(resetValue.endDate);
    setIsNow(resetValue.isNow);
  }, [resetValue.endDate, resetValue.isNow, resetValue.startDate]);

  const setRange = useCallback((): void => {
    if (!startDate?.isValid || !endDate?.isValid) {
      setDateErrorMsg(t('common:component.datetime-range-select.errors.invalid-dates'));
      return;
    }

    if (startDate > endDate) {
      setDateErrorMsg(t('common:component.datetime-range-select.hint.from-date-is-before-to-date'));
      return;
    }

    handleClosePopover();

    if (onDateChange) {
      try {
        onDateChange(startDate, endDate.endOf('minute'));
      } catch (error) {
        setDateErrorMsg(t('common:component.datetime-range-select.errors.update-failed'));
        return;
      }
    }

    setDateLabel(`${startDate.toFormat('MMM d, yyyy hh:mm a')} - ${endDate.toFormat('MMM d, yyyy hh:mm a')}`);
  }, [endDate, onDateChange, startDate, t]);

  useEffect(() => {
    if (!isNow) return;
    const handleUpdateTime = (): void => {
      setEndDate(DateTime.now());
    };
    handleUpdateTime();
    const updateTime = setInterval(handleUpdateTime, Time.seconds(1));
    return () => clearInterval(updateTime);
  }, [isNow]);

  const handleCurrentTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsNow(event.target.checked);
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      setDateValid(false);
      setDateErrorMsg(undefined);
      return;
    }

    if ((startDate.isValid && endDate.isValid && startDate > endDate)) {
      setDateValid(false);
      setDateErrorMsg(t('common:component.datetime-range-select.hint.from-date-is-before-to-date'));
      return;
    }

    setDateValid(true);
    setDateErrorMsg(undefined);
  }, [startDate, endDate, isNow, showNow, t]);

  const handleClose = (): void => {
    if (viewStartDateCalendar) {
      setViewStartDateCalendar(false);
      return;
    }
    if (viewEndDateCalendar) {
      setViewEndDateCalendar(false);
      return;
    }

    cancelSelect();
  };

  const viewLabel = useMemo(() => label ?? dateLabel, [dateLabel, label]);
  const errorMessage = useMemo(() => errorMsg ?? dateErrorMsg, [dateErrorMsg, errorMsg]);

  const renderActionList = useCallback(({ onSetToday }: { onSetToday: () => void }) => {
    const ActionList: FC<PickersActionBarProps> = (actionBarProps: PickersActionBarProps) => {
      const { onAccept, className } = actionBarProps;
      const actions = [
        { text: t('common:component.datetime-range-select.labels.today'), method: onSetToday },
        { text: t('common:common.action.ok'), method: onAccept },
      ];
      return (
        <Stack className={className} direction="row" justifyContent="space-between" p={1} >
          {actions.map(({ text, method }) => (
            <Button key={text} onClick={() => method()}>
              {text}
            </Button>
          ))}
        </Stack>
      );
    };
    return ActionList;
  }, [t]);

  const commonDateTimePickerProps: DateTimePickerProps<DateTime> = useMemo(() => ({
    ampm: true,
    autoFocus: true,
    disableFuture: !futureDates,
    open: smallScreen ? false : undefined,
    timeSteps: {
      hours: timeSteps.hours,
      minutes: timeSteps.minutes,
      seconds: timeSteps.seconds,
    },
    slotProps: {
      layout: {
        sx: {
          display: 'block',
        },
      },
      actionBar: {
        sx: {
          '&': {
            justifyContent: 'space-between',
          },
        },
      },
      openPickerIcon: {
        fontSize: 'small',
      },
      textField: {
        variant: 'outlined',
        size: 'small',
        fullWidth: true,
      },
    },
    viewRenderers: { hours: renderDigitalClockTimeView, minutes: null },
  }), [futureDates, smallScreen, timeSteps.hours, timeSteps.minutes, timeSteps.seconds]);

  const startDateTimeComponent = useMemo(() => (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2} sx={{ mt: 1 }}>
        <Typography sx={{ textTransform: 'uppercase' }} variant="caption">
          {fromLabel ?? t('common:component.datetime-range-select.labels.from')}
        </Typography>
        <Box onClick={smallScreen ? () => setViewStartDateCalendar(true) : undefined}>
          <DateTimePicker
            onChange={setStartDate}
            slots={{
              openPickerIcon: CalendarIcon,
              actionBar: renderActionList({ onSetToday: () => setStartDate(DateTime.now().startOf('day')) }),
            }}
            value={startDate}
            {...commonDateTimePickerProps}
          />
        </Box>
      </Stack>
    </Box>
  ), [CalendarIcon, commonDateTimePickerProps, fromLabel, renderActionList, smallScreen, startDate, t]);

  const endDateTimeComponent = useMemo(() => (
    <Box sx={{ p: 2 }}>
      <Stack alignItems="center" direction="row">
        <Box>
          <Typography sx={{ textTransform: 'uppercase' }} variant="caption">
            {toLabel ?? t('common:component.datetime-range-select.labels.to')}
          </Typography>
        </Box>
        {showNow
          && <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox checked={isNow} onChange={handleCurrentTime} size="small" />}
              label={
                <Typography sx={{ textTransform: 'uppercase' }} variant="caption">
                  {nowLabel ?? t('common:component.datetime-range-select.labels.now')}
                </Typography>
              }
              sx={{ mr: 0 }}
            />
          </Box>
        }
      </Stack>
      <Box
        onClick={smallScreen && !(showNow && isNow) ? () => setViewEndDateCalendar(true) : undefined}
        sx={{ mt: 1 }}
      >
        <DateTimePicker
          disabled={showNow && isNow}
          onChange={setEndDate}
          slots={{
            openPickerIcon: CalendarIcon,
            actionBar: renderActionList({ onSetToday: () => setEndDate(DateTime.now()) }),
          }}
          value={endDate}
          {...commonDateTimePickerProps}
        />
      </Box>
    </Box>
  ), [
    CalendarIcon,
    commonDateTimePickerProps,
    endDate,
    isNow,
    nowLabel,
    renderActionList,
    showNow,
    smallScreen,
    t,
    toLabel,
  ]);

  const renderMainView = useCallback(() => (
    <Stack>
      {quickSelectOptions
        && <>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ textTransform: 'uppercase' }} variant="caption">
              {quickSelectLabel ?? t('common:component.datetime-range-select.labels.quick-select')}
            </Typography>
            <Stack direction="row" justifyContent="space-around" sx={{ mt: 1 }}>
              {quickSelectOptions.map(({ label, value }) => (
                <Button key={label} onClick={() => handleQuickSelection(value)} size="small" variant="text">
                  {label}
                </Button>
              ))}
            </Stack>
          </Box>
          <Divider />
        </>
      }
      {startDateTimeComponent}
      <Divider />
      {endDateTimeComponent}
      {errorMessage && (
        <Box sx={{ p: 2 }}>
          <MuiAlert color="error" icon={null}>{errorMessage}</MuiAlert>
        </Box>
      )}
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="end" mt={1} spacing={1}>
          <Button onClick={cancelSelect} size="small" variant="text">
            {t('common:component.datetime-range-select.labels.cancel')}
          </Button>
          <Button
            disabled={!isValid || !dateValid}
            onClick={setRange}
            size="small"
            variant="contained"
          >
            {t('common:component.datetime-range-select.labels.set')}
          </Button>
        </Stack>
      </Box>
    </Stack>
  ), [
    cancelSelect,
    dateValid,
    endDateTimeComponent,
    errorMessage,
    handleQuickSelection,
    isValid,
    quickSelectLabel,
    quickSelectOptions,
    setRange,
    startDateTimeComponent,
    t,
  ]);

  const renderDesktopView = useCallback(() => (
    <Button
      data-testid={props['data-testid'] ? `${props['data-testid']}-button` : undefined}
      onClick={handleClickButton}
      sx={{
        borderColor: theme.palette.grey[400],
        '&:hover': {
          borderColor: theme.palette.grey[600],
        },
        display: 'block',
        paddingRight: theme.spacing(0.5),
        textTransform: 'none',
        width: 300,
        ...sx,
      }}
      title={viewLabel ?? t('common:component.datetime-range-select.labels.specify-date-range')}
      variant="outlined"
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1}>
        <Stack flex={1} sx={{ overflow: 'hidden', textAlign: 'left' }}>
          {!viewLabel
            ? <Typography color="text.secondary" fontStyle="italic" variant="body2">
              {t('common:component.datetime-range-select.labels.specify-date-range')}
            </Typography>
            : <Typography
              color="text.primary"
              noWrap
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              variant="body2"
            >
              {viewLabel}
            </Typography>
          }
        </Stack>
        <IconButton><CalendarIcon /></IconButton>
      </Stack>
    </Button>
  ), [CalendarIcon, handleClickButton, props, sx, t, theme, viewLabel]);

  const desktopPopoverSx: SxProps = {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    transformOrigin: {
      horizontal: 'left',
      vertical: 'top',
    },
  };

  const mobilePopoverSx: SxProps = {
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'center',
    },
    transformOrigin: {
      horizontal: 'center',
      vertical: 'bottom',
    },
  };

  return (
    <LocalizationProvider adapterLocale={I18n.getInstance().language.toLowerCase()} dateAdapter={AdapterLuxon}>
      {smallScreen
        ? <IconButton
          onClick={handleClickButton}
          sx={{ padding: 0 }}
        >
          <DateRangeIcon />
        </IconButton>
        : renderDesktopView()
      }
      <Popover
        data-testid={props['data-testid'] ? `${props['data-testid']}-popover` : undefined}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        open={open !== undefined ? open : pickerOpen}
        sx={{
          mt: 1,
          ...smallScreen ? mobilePopoverSx : desktopPopoverSx,
        }}
      >
        <Box sx={{ width: 325, ...pickerSx }}>
          {viewStartDateCalendar
            ? <StaticDateTimePicker
              defaultValue={startDate}
              label={fromLabel ?? t('common:component.datetime-range-select.labels.from')}
              onAccept={(value) => {
                setStartDate(value);
                handleClose();
              }}
              onClose={handleClose}
            />
            : viewEndDateCalendar
              ? <StaticDateTimePicker
                defaultValue={endDate}
                label={toLabel ?? t('common:component.datetime-range-select.labels.to')}
                onAccept={(value) => {
                  setEndDate(value);
                  handleClose();
                }}
                onClose={handleClose}
              />
              : renderMainView()
          }
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};
