import { Thing } from '@eagle/core-data-types';
import {
  Acceleration,
  ActivityAccumulatorSummary,
  BucketedAccumulatorSummary,
  CameraEventTypes,
  CameraStatus,
  CommonTrackingLocationUpdateData,
  DangerousCornering,
  DeviceInstalled,
  DeviceUninstalled,
  DriverBehaviorEventTypes,
  DuressButtonActivated,
  DuressEventTypes,
  EventRecordEventTypes,
  FaultEventTypes,
  FaultUpdate,
  FeatureTypes,
  ImageCaptured,
  InertiaAccelerationData,
  InertiaEventTypes,
  InstallationEventTypes,
  PersonChanged,
  PersonEventTypes,
  Rollover,
  SimpleActivityEventTypes,
  SpeedingFinish,
  SpeedingStart,
  ThingEvent,
  TrackingEventTypes,
  TrackingSummary,
  VehicleSeatEventTypes,
  Velocity,
  VideoCaptured,
  VideoStatus
} from '@eagle/data-function-types';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SpeedIcon from '@mui/icons-material/Speed';
import { Box, Chip, Divider, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { isObject } from 'lodash';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { T_ONE } from '../../constants';
import { useCustomRoutes, useSmallScreen } from '../../hooks';
import { EventFields } from '../../pages/event-history/history-drawer/history-drawer.types';
import { CacheDataTypes } from '../../types';
import { getFormattedFileName, HeaderWithSpaces, kphToMph, testid } from '../../util';
import { getEventFeatureV2, getEventHint, getEventLabelV2, getEventTranslation } from '../../util/events';
import { EventAddress } from '../events/event-address';
import { FetchOne } from '../fetch';
import { useBoolFlag } from '../flags';
import { FormatAddress, FormatStack, FormatTimestamp, FormatTimezone } from '../format';
import { InfoIcon } from '../icons';
import { MiddleSpinner } from '../middle-spinner';
import { DataRow, LinkButton, MediaViewButton } from '../static-data';
import { DataImageModal, DataVideoModal } from '../static-data/data-row-modal';
import { useFaultDescriptionQuery } from './events-table.queries';
import { CategoryProps, TableEventIcon } from './types';

const { ROLLOVER, ...inertiaEvents } = InertiaEventTypes;
const { DURESS_BUTTON_ACTIVATED } = DuressEventTypes;

type OtherFeatures = 'abs-activated' | 'vsc-activated' | 'airbag-deployed';

// Temporary definitions until types are added
enum AdasEventTypes {
  PRE_COLLISION_WARNING_OCCURRED = 'pre-collision-warning-occurred',
  LANE_DEPARTURE_OCCURRED = 'lane-departure-occurred',
}
enum AdditionalDriverBehaviorEventTypes {
  ATTENTION_WARNING_OCCURRED = 'attention-warning-occurred',
  DISTRACTION = 'distraction',
  NOT_WEARING_MASK = 'not-wearing-mask',
  SUNGLASSES_DETECTED = 'sunglasses-detected',
  CAMERA_BLOCKED = 'camera-blocked',
}

export const EventCategories = {
  [EventFields.DRIVER_BEHAVIOR]: Object.values({ ...inertiaEvents, ...DriverBehaviorEventTypes, ...VehicleSeatEventTypes, ...AdditionalDriverBehaviorEventTypes }),
  [EventFields.MEDIA]: Object.values(CameraEventTypes),
  [EventFields.SAFETY_INCIDENT]: Object.values({ ROLLOVER, DURESS_BUTTON_ACTIVATED, ...AdasEventTypes }),
  [EventFields.TRACKING]: Object.values(TrackingEventTypes),
  [EventFields.OTHER]: Object.values({ ...EventRecordEventTypes, ...SimpleActivityEventTypes }),
};

const isEmptyOrNull = (value: unknown): boolean => {
  return value === null || value === undefined || (Array.isArray(value) && value.length === 0);
};

export const EventTableCategory: FC<CategoryProps> = ({ eventTypeId, feature }) => {
  const { t } = useTranslation(['common']);
  const header = Object.entries(EventCategories)
    .filter(([_key, value]: [string, string[]]) => value.includes(eventTypeId))
    .map(([key]) => key)[0];

  if (!header) return <HeaderWithSpaces header={feature} />;
  return <span style={{ textTransform: 'capitalize' }}>{t(`common:component.filter-dropdown.labels.${header}`)}</span>;
};

export const EventTableEventIcon: FC<TableEventIcon> = ({ event, showDateBelowEvent }) => {
  const { eventTypeId, occurred, feature, featureTypeId } = event;
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');
  const isHintEnabled = useBoolFlag('portals-event-translation-hints-feature');
  const { label, hint, featureInstance, featureLabel } = flagUseEventV3 ? getEventTranslation(feature, eventTypeId) : { label: getEventLabelV2(featureTypeId, eventTypeId), hint: getEventHint(feature, eventTypeId), featureInstance: undefined, featureLabel: getEventFeatureV2(feature) };
  const theme = useTheme();
  const smallScreen = useSmallScreen();
  const iconStyling = { pr: '.5rem', m: 'auto 0' };

  const EventIcon = (): JSX.Element => {
    switch (eventTypeId) {
      case InertiaEventTypes.ROLLOVER: return <ErrorIcon sx={{ color: theme.palette.error.main, ...iconStyling }} />;
      case InertiaEventTypes.DANGEROUS_CORNERING: return <ErrorIcon sx={{ color: theme.palette.error.main, ...iconStyling }} />;
      case DuressEventTypes.DURESS_BUTTON_ACTIVATED: return <ErrorIcon sx={{ color: theme.palette.warning.main, ...iconStyling }} />;
      case TrackingEventTypes.POWER_ON: return <PowerSettingsNewIcon sx={{ color: theme.palette.success.main, ...iconStyling }} />;
      case TrackingEventTypes.POWER_OFF: return <PowerSettingsNewIcon sx={{ color: theme.palette.grey[500], ...iconStyling }} />;
      case TrackingEventTypes.SPEEDING_START:
      case TrackingEventTypes.SPEEDING_FINISH: return <SpeedIcon sx={{ color: theme.palette.error.main, ...iconStyling }} />;
      default: return <CircleIcon sx={{ color: theme.palette.grey[500], ...iconStyling }} />;
    }
  };

  const primary = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? featureLabel : label;
  const secondary = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? label : featureLabel;

  return (
    <Box sx={{ display: 'flex' }}>
      <EventIcon />
      <Stack direction="column">
        <Stack direction="row" spacing={theme.spacing(1)}>
          <Typography>{primary}</Typography>
          {featureInstance && <Chip label={featureInstance} variant="outlined" size="small" />}
        </Stack>
        <Stack direction="row" spacing={theme.spacing(1)}>
          <Typography variant="caption" sx={{ paddingTop: isHintEnabled ? theme.spacing(0.6) : 0 }}>
            {secondary}
          </Typography>
          {isHintEnabled && hint &&
            <Tooltip data-testid="event-hint" title={hint} arrow>
              <IconButton size="small" aria-label="info">
                <InfoIcon fontSize="small" color="action" />
              </IconButton>
            </Tooltip>}
        </Stack>
        {occurred && showDateBelowEvent
          && <Typography sx={{ mt: smallScreen ? 'inherit' : '-0.5rem' }} variant="caption">
            <FormatTimestamp value={occurred} format="shortWithSeconds" />
          </Typography>
        }
      </Stack>
    </Box>
  );
};

export const FeatureCell: FC<{ feature: string; eventTypeId: string; featureTypeId: string }> = ({ feature, eventTypeId, featureTypeId }) => {
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');
  const { label, featureLabel } = flagUseEventV3 ? getEventTranslation(feature, eventTypeId) : { label: getEventLabelV2(featureTypeId, eventTypeId), featureLabel: getEventFeatureV2(feature) };

  return (
    <DataRow
      label={featureLabel}
      value={label}
    />
  );
};

export const DataRowTrueOrFalse: FC<{ label: JSX.Element | string; flag?: boolean }> = ({ label, flag }) => {
  const { t } = useTranslation(['common']);

  if (flag === undefined) return <></>;

  return (
    <DataRow
      label={label}
      value={flag ? t('common:common.action.true') : t('common:common.action.false')}
    />
  );
};

const VelocityDisplay: FC<{ velocity: Velocity; displayName?: string }> = ({ velocity, displayName }) => {
  const { t } = useTranslation(['common']);

  return (
    <DataRow
      label={t(displayName || 'common:component.events.labels.velocity')}
      value={
        <>
          <DataRow
            label={t('common:component.events.labels.speed')}
            value={t('common:component.events.labels.speed-value', { kph: velocity.speed, mph: kphToMph(velocity.speed) })}
          />
          {velocity.heading !== undefined && <DataRow
            label={t('common:component.events.labels.heading')}
            value={velocity.heading}
          />}
        </>
      }
    />
  );
};

const AccelerationDisplay: FC<{ acceleration: Acceleration }> = ({ acceleration }) => {
  const { t } = useTranslation(['common']);

  if (!acceleration.axisX && !acceleration.axisY && !acceleration.axisZ) {
    return null;
  }

  return (
    <DataRow
      label={t('common:component.events.labels.acceleration')}
      value={
        <>
          {acceleration.axisX !== undefined && <Typography>{t('common:component.events.labels.axis', { axis: t('common:component.events.labels.x-axis'), data: acceleration.axisX })}</Typography>}
          {acceleration.axisY !== undefined && <Typography>{t('common:component.events.labels.axis', { axis: t('common:component.events.labels.y-axis'), data: acceleration.axisY })}</Typography>}
          {acceleration.axisZ !== undefined && <Typography>{t('common:component.events.labels.axis', { axis: t('common:component.events.labels.z-axis'), data: acceleration.axisZ })}</Typography>}
        </>
      }
    />
  );
};

export const DataContainsLocation = (data: CommonTrackingLocationUpdateData, feature: string, eventTypeId: string, featureTypeId: string): { leftColumnFormatting: JSX.Element; rightColumnFormatting: JSX.Element } => {
  const { t } = useTranslation(['common']);
  const { location, velocity, moving, powerOn, ...rest } = data;

  const leftColumnFormatting = (
    <>
      <FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
      {location && (
        <>
          {location.address ? (
            <DataRow
              label={t('common:component.events.labels.address')}
              value={<FormatAddress value={location.address} />}
            />
          ) : (
            <DataRow
              label={t('common:component.events.labels.address')}
              value={<EventAddress location={location} />}
            />
          )}
          <DataRow label={t('common:component.events.labels.latitude')} value={<>{location.latitude}</>} />
          <DataRow label={t('common:component.events.labels.longitude')} value={<>{location.longitude}</>} />
          {location.accuracy !== undefined && (
            <DataRow label={t('common:component.events.labels.accuracy')} value={<>{location.accuracy}</>} />
          )}
        </>
      )}
    </>
  );

  const rightColumnFormatting = (
    <>
      {rest && <NoEventFormat data={rest} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} renderStack={false} />}
      {'moving' in data && <DataRowTrueOrFalse label={<>{t('common:component.events.labels.moving')}</>} flag={moving} />}
      {'powerOn' in data && <DataRowTrueOrFalse label={<>{t('common:event-descriptions-v3.tracking.power-on.labels')}</>} flag={powerOn} />}
      {velocity && <VelocityDisplay velocity={velocity} />}
      {'acceleration' in data && data.acceleration !== '' && <AccelerationDisplay acceleration={data.acceleration as Acceleration} />}
      {location && (
        <>
          {location.source && <DataRow label={t('common:component.events.labels.source')} value={<>{location.source}</>} />}
          {location.timeZone && (
            <DataRow label={t('common:component.events.labels.timezone')} value={<FormatTimezone zone={location.timeZone.id} />} />
          )}
          {location.altitude && (
            <>
              <DataRow label={t('common:component.events.labels.altitude')} value={<>{location.altitude.value}</>} />
              {location.altitude.accuracy !== undefined && (
                <DataRow label={t('common:component.events.labels.altitude-accuracy')} value={<>{location.altitude.accuracy}</>} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  return { leftColumnFormatting, rightColumnFormatting };
};

const CommonTrackingLocationUpdateFormat: FC<{ data: CommonTrackingLocationUpdateData; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(data, feature, eventTypeId, featureTypeId);
  return <FormatStack left={leftColumnFormatting} right={rightColumnFormatting} />;
};

const ActivityAccumulatorSummaryFormat: FC<{ data: ActivityAccumulatorSummary; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);

  return (
    <FormatStack
      left={
        <>
          <FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
          <DataRow label={t('common:component.events.labels.start')} value={<FormatTimestamp format="long" value={data.start} />} />
          <DataRow label={t('common:component.events.labels.finish')} value={<FormatTimestamp format="long" value={data.finish} />} />
        </>
      }
      right={
        <>
          {data.distance && <DataRow label={t('common:component.events.labels.distance')} value={<>{data.distance}</>} />}
          {data.duration && <DataRow label={t('common:component.events.labels.duration')} value={<>{data.duration}</>} />}
          {'count' in data ? <DataRow label={t('common:component.events.labels.count')} value={<>{data.count}</>} /> : undefined}
        </>
      }
    />
  );
};

const BucketedAccumulatorSummaryFormat: FC<{ data: BucketedAccumulatorSummary; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);

  return (
    <FormatStack
      left={
        <>
          <FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
          <DataRow label={t('common:component.events.labels.start')} value={<FormatTimestamp format="long" value={data.start} />} />
          <DataRow label={t('common:component.events.labels.finish')} value={<FormatTimestamp format="long" value={data.finish} />} />
        </>
      }
      right={
        <>
          {Object.entries(data.buckets).map(([bucket, value]) => (
            <DataRow key={bucket} label={t(`common:event-descriptions-v3.${feature}.buckets.${bucket}.labels`)} value={<>
              {'duration' in value && <DataRow label={t('common:component.events.labels.duration')} value={<>{value.duration}</>} />}
              {'increase' in value && <DataRow label={t('common:component.events.labels.increase')} value={<>{value.increase}</>} />}
            </>} />
          ))}
        </>
      }
    />
  );
};

const OtherDriverBehaviorFormat: FC<{ data: CommonTrackingLocationUpdateData & { type: string }; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);
  const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(data, feature, eventTypeId, featureTypeId);

  return (
    <FormatStack
      left={leftColumnFormatting}
      right={
        <>
          {rightColumnFormatting}
          <DataRow label={t('common:component.events.labels.data-type')} value={<>{data.type}</>} />
        </>
      }
    />
  );
};

const InertiaFormat: FC<{ data: InertiaAccelerationData | DangerousCornering; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);

  const leftColumnFormatting = (
    <>
      <FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
      {'location' in data && data.location && (
        <>
          {data.location.address && (
            <DataRow
              label={t('common:component.events.labels.address')}
              value={<FormatAddress value={data.location.address} />}
            />
          )}
          <DataRow label={t('common:component.events.labels.latitude')} value={<>{data.location.latitude}</>} />
          <DataRow label={t('common:component.events.labels.longitude')} value={<>{data.location.longitude}</>} />
          {data.location.accuracy && (
            <DataRow label={t('common:component.events.labels.accuracy')} value={<>{data.location.accuracy}</>} />
          )}
        </>
      )}
    </>
  );

  const rightColumnFormatting = (
    <>
      {'velocity' in data && data.velocity && <VelocityDisplay velocity={data.velocity} />}
      {'acceleration' in data && data.acceleration && <AccelerationDisplay acceleration={data.acceleration} />}
      {'location' in data && data.location && (
        <>
          <DataRow label={t('common:component.events.labels.source')} value={<>{data.location.source}</>} />
          {data.location.timeZone && (
            <DataRow label={t('common:component.events.labels.timezone')} value={<FormatTimezone zone={data.location.timeZone.id} />} />
          )}
          {data.location.altitude && (
            <>
              <DataRow label={t('common:component.events.labels.altitude')} value={<>{data.location.altitude.value}</>} />
              {data.location.altitude.accuracy && (
                <DataRow label={t('common:component.events.labels.altitude-accuracy')} value={<>{data.location.altitude.accuracy}</>} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  return <FormatStack left={leftColumnFormatting} right={rightColumnFormatting} />;
};

const RolloverFormat: FC<{ data: Rollover; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);

  const leftColumnFormatting = (
    <>
      <FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
      {data.location?.address && <DataRow label={t('common:component.events.labels.address')} value={<EventAddress location={data.location} />} />}
      {data.location?.latitude && <DataRow label={t('common:component.events.labels.latitude')} value={<>{data.location?.latitude}</>} />}
      {data.location?.longitude && <DataRow label={t('common:component.events.labels.longitude')} value={<>{data.location?.longitude}</>} />}
    </>
  );
  const rightColumnFormatting = (
    <>
      {data.acceleration && <AccelerationDisplay acceleration={data.acceleration} />}
      {data.location?.source && <DataRow label={t('common:component.events.labels.source')} value={<>{data.location.source}</>} />}
      {data.location?.timeZone?.id && <DataRow label={t('common:component.events.labels.timezone')} value={<FormatTimezone zone={data.location?.timeZone?.id} />} />}
    </>
  );

  return <FormatStack left={leftColumnFormatting} right={rightColumnFormatting} />;
};

const TrackingSummaryFormat: FC<{ data: TrackingSummary; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data }) => {
  const { t } = useTranslation(['common']);
  return (
    <FormatStack
      left={
        <>
          <DataRow
            label={t('common:event-descriptions-v3.tracking.start.labels')}
            value={<FormatTimestamp value={data.start} />}
          />
          <DataRow
            label={t('common:event-descriptions-v3.tracking.finish.labels')}
            value={<FormatTimestamp value={data.finish} />}
          />
        </>
      }
      right={
        <>
          {data.maxVelocity && <VelocityDisplay velocity={data.maxVelocity} displayName={t('common:event-descriptions-v3.tracking.maxVelocity.labels')} />}
        </>
      }
    />
  );
};

const SpeedingFormat: FC<{ data: SpeedingStart | SpeedingFinish; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);

  if (!data.speedingThreshold) return <NoEventFormat data={data} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />;

  const speed = data.speedingThreshold.speed;

  return (
    <FormatStack
      left={<FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />}
      right={
        <DataRow
          label={t('common:component.events.labels.speed')}
          value={t('common:component.events.labels.speed-value', { kph: speed, mph: kphToMph(speed) })}
        />
      }
    />
  );
};

const DeviceFormat: FC<{ data: DeviceInstalled | DeviceUninstalled; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);

  return (
    <FormatStack
      left={
        <>
          <FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
          <DataRow label={t('common:component.events.thing-event.labels.table.device-id')} value={<>{data.deviceId.deviceId}</>} />
        </>
      }
      right={<></>}
    />
  );
};

const DuressButtonActivatedFormat: FC<{ data: DuressButtonActivated; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);

  return (
    <FormatStack
      left={
        <>
          <FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
          {data.location?.address && <DataRow label={t('common:component.events.labels.address')} value={<EventAddress location={data.location} />} />}
          {data.location?.latitude && <DataRow label={t('common:component.events.labels.latitude')} value={<>{data.location?.latitude}</>} />}
          {data.location?.longitude && <DataRow label={t('common:component.events.labels.longitude')} value={<>{data.location?.longitude}</>} />}
        </>
      }
      right={
        <>
          {data.location?.source && <DataRow label={t('common:component.events.labels.source')} value={<>{data.location?.source}</>} />}
          {data.location?.timeZone?.id && <DataRow label={t('common:component.events.labels.timezone')} value={<FormatTimezone zone={data.location?.timeZone?.id} />} />}
        </>
      }
    />
  );
};

const PersonChangedFormat: FC<{ data: PersonChanged; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common', 'terms']);
  const { person } = useCustomRoutes();

  const link = data.personId ? { display: t('common:common.action.view'), url: `/${person}/${data.personId}` } : undefined;

  return (
    <FormatStack
      left={<FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />}
      right={
        <DataRow
          action={<LinkButton link={link} data-testid='action-button-view' />}
          label={t('terms:person', { count: T_ONE })}
          value={<>{data.personId ? data.personId : t('common:common.hint.no-results.person')}</>}
        />
      }
    />
  );
};

const CameraStatusFormat: FC<{ data: CameraStatus | VideoStatus; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, feature, eventTypeId, featureTypeId }) => {
  const { t, i18n } = useTranslation(['common', 'track']);

  if ('statuses' in data) {
    const { statuses } = data;
    return (
      <FormatStack
        left={<FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />}
        right={
          <>
            {statuses.map((status, index) => (
              <>
                <FormatStack
                  key={index}
                  left={<DataRow label={t('common:terms.start')} value={<FormatTimestamp format="long" value={status.start} />} />}
                  right={<DataRow label={t('common:component.events.labels.status')} value={i18n.exists(`track:page.camera-content.camera-status.labels.${status.status}`) ? t(`track:page.camera-content.camera-status.labels.${status.status}`) : status.status} />}
                />
                <Divider />
              </>
            ))}
          </>}
      />
    );
  }

  return (
    <FormatStack
      left={<FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />}
      right={<DataRow label={t('common:component.events.labels.status')} value={i18n.exists(`track:page.camera-content.camera-status.labels.${data.status}`) ? t(`track:page.camera-content.camera-status.labels.${data.status}`) : data.status} />}
    />
  );
};

const ImageCapturedFormat: FC<{ data: ImageCaptured; thingId: string; occurred: Date; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, thingId, occurred, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = (): void => setShowModal(true);
  const handleModalClose = (): void => setShowModal(false);

  return (
    <FetchOne
      id={thingId}
      dataType={CacheDataTypes.THING}
      renderFactory={({ display }: Thing) => {
        const url = data.images.original;
        const displayText = getFormattedFileName(display, url as unknown as string, occurred, feature);
        return (
          <>
            <FormatStack
              left={<FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />}
              right={
                <DataRow
                  action={<MediaViewButton display={t('common:common.action.view')} onClick={handleModalOpen} />}
                  label={t('common:common.labels.images')}
                  value={displayText}
                />
              }
            />
            <DataImageModal fileName={displayText} handleClose={handleModalClose} open={showModal} url={url as unknown as string} />
          </>
        );
      }}
    />
  );
};

const VideoCapturedFormat: FC<{ data: VideoCaptured; thingId: string; occurred: Date; feature: string; eventTypeId: string; featureTypeId: string }> = ({ data, thingId, occurred, feature, eventTypeId, featureTypeId }) => {
  const { t } = useTranslation(['common']);
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = (): void => setShowModal(true);
  const handleModalClose = (): void => setShowModal(false);

  return (
    <FetchOne
      id={thingId}
      dataType={CacheDataTypes.THING}
      renderFactory={({ display }: Thing) => {
        const url = data.videos.original[0];
        const displayText = getFormattedFileName(display, url as unknown as string, occurred, feature);
        return (
          <>
            <FormatStack
              left={<FeatureCell feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />}
              right={
                <DataRow
                  action={<MediaViewButton display={t('common:common.action.view')} onClick={handleModalOpen} />}
                  label={t('common:common.labels.videos')}
                  value={displayText}
                />
              }
            />
            <DataVideoModal fileName={displayText} handleClose={handleModalClose} open={showModal} url={url as unknown as string} />
          </>
        );
      }}
    />
  );
};

const J1939Format: FC<{ data: FaultUpdate; feature: string; eventTypeId: string; featureTypeId: string; thingId: string }> = ({
  data,
  feature,
  eventTypeId,
  featureTypeId,
  thingId,
}) => {
  const faultDescriptionQuery = useFaultDescriptionQuery({ code: data.code, failureModeIdentifier: data.failureModeIdentifier, thingId });

  if (faultDescriptionQuery.status === 'pending') {
    return <MiddleSpinner sx={{ m: 1 }} />;
  }

  const updatedData = {
    ...data,
    description: faultDescriptionQuery.data?.description ?? data.description,
    faultCode: faultDescriptionQuery.data?.faultCode ?? data.faultCode,
  };

  return (
    <NoEventFormat data={updatedData} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />
  );
};

const NoEventFormat: FC<{ data: unknown; feature: string; eventTypeId: string; featureTypeId: string; renderStack?: boolean }> = ({ data, feature, eventTypeId, featureTypeId, renderStack = true }) => {
  const { t } = useTranslation(['common']);

  if (typeof data === 'object' && data !== null) {
    const dataObj = data as Record<string, unknown>;

    if ('location' in dataObj) {
      return <CommonTrackingLocationUpdateFormat data={dataObj as CommonTrackingLocationUpdateData} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />;
    }

    if ('acceleration' in dataObj) {
      return <InertiaFormat data={dataObj as InertiaAccelerationData} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />;
    }

    if ('speedingThreshold' in dataObj) {
      return <SpeedingFormat data={dataObj as SpeedingStart | SpeedingFinish} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />;
    }

    if ('deviceId' in dataObj && 'feature' in dataObj) {
      return <DeviceFormat data={dataObj as unknown as DeviceInstalled | DeviceUninstalled} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />;
    }

    if ('location' in dataObj && 'velocity' in dataObj && 'feature' in dataObj) {
      return <DuressButtonActivatedFormat data={dataObj as DuressButtonActivated} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />;
    }

    if ('personId' in dataObj && 'feature' in dataObj) {
      return <PersonChangedFormat data={dataObj as unknown as PersonChanged} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} />;
    }

    const formatValue = (value: unknown): React.ReactNode => {
      if (value instanceof Date) {
        return <FormatTimestamp value={value} format="long" />;
      }
      if (isObject(value)) {
        return <NoEventFormat data={value} feature={feature} eventTypeId={eventTypeId} featureTypeId={featureTypeId} renderStack={false} />;
      }
      return String(value);
    };

    const formatFeature = (featureId: string): string => {
      if (featureId.includes('/')) {
        return featureId.replace(/\//g, '.');
      }
      return featureId;
    };

    // Fallback to default object rendering
    const entries = Object.entries(dataObj).filter(([_, value]) => !isEmptyOrNull(value));

    if (!renderStack) {
      return (
        <>
          {entries.map(([key, value]) => (
            <DataRow
              key={key}
              label={t(`common:event-descriptions-v3.${formatFeature(feature)}.${key}.labels`)}
              value={formatValue(value)}
              data-testid={testid`data-row-${key}`}
            />
          ))}
        </>
      );
    }

    return (
      <FormatStack
        left={
          <Fragment>
            {entries.map(([key, value], index) =>
              index % 2 === 0 && (
                <DataRow
                  key={key}
                  label={t(`common:event-descriptions-v3.${formatFeature(feature)}.${key}.labels`)}
                  value={formatValue(value)}
                  data-testid={testid`data-row-${key}`}
                />
              ),
            )}
          </Fragment>
        }
        right={
          <Fragment>
            {entries.map(([key, value], index) =>
              index % 2 !== 0 && (
                <DataRow
                  key={key}
                  label={t(`common:event-descriptions-v3.${formatFeature(feature)}.${key}.labels`)}
                  value={formatValue(value)}
                  data-testid={testid`data-row-${key}`}
                />
              ),
            )}
          </Fragment>
        }
      />
    );
  }

  return (
    <Stack sx={{ '& > div': { my: 0.5 }, m: 1, width: '100%' }}>
      <Typography variant="body2" fontStyle="italic" color="text.secondary" sx={{ px: 2.5, py: 4 }}>
        {t('common:component.events.thing-event.labels.table.no-formatting-available')}
      </Typography>
      <DataRow
        label={t('common:component.events.thing-event.labels.table.raw-data')}
        value={<pre>{JSON.stringify(data, null, 4)}</pre>}
      />
    </Stack>
  );
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function EntryDataBody<T extends Omit<ThingEvent, 'schema'>>({ event }: { event: T }): JSX.Element {
  const { t } = useTranslation(['common']);

  if (!event.data) {
    return <>{t('common:component.events.thing-event.labels.table.filter-no-results')}</>;
  }

  return <EntryDataContent event={event} />;
}

const EntryDataContent: React.FC<{ event: Omit<ThingEvent, 'schema'> }> = ({ event }) => {
  const renderCommonFormat = (data: CommonTrackingLocationUpdateData, feature: string, eventTypeId: string, featureTypeId: string): JSX.Element => {
    const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(data, feature, eventTypeId, featureTypeId);
    return <FormatStack left={leftColumnFormatting} right={rightColumnFormatting} />;
  };

  switch (event.featureTypeId as FeatureTypes & OtherFeatures) {
    case FeatureTypes.ACTIVITY_ACCUMULATOR_V0:
      switch (event.eventTypeId) {
        case 'summary':
          return <ActivityAccumulatorSummaryFormat data={event.data as ActivityAccumulatorSummary} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.BUCKETED_ACCUMULATOR_V0:
      switch (event.eventTypeId) {
        case 'summary':
          return <BucketedAccumulatorSummaryFormat data={event.data as BucketedAccumulatorSummary} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.CAMERA_V0:
      switch (event.eventTypeId) {
        case CameraEventTypes.CAMERA_STATUS:
        case CameraEventTypes.VIDEO_STATUS:
          return <CameraStatusFormat data={event.data as CameraStatus | VideoStatus} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        case CameraEventTypes.IMAGE_CAPTURED:
          return <ImageCapturedFormat data={event.data as ImageCaptured} thingId={event.thingId} occurred={event.occurred} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        case CameraEventTypes.VIDEO_CAPTURED:
          return <VideoCapturedFormat data={event.data as VideoCaptured} thingId={event.thingId} occurred={event.occurred} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.DRIVER_BEHAVIOR_V0:
      switch (event.eventTypeId) {
        case DriverBehaviorEventTypes.EYES_CLOSED:
        case DriverBehaviorEventTypes.FATIGUE_RISK_DRIVING:
        case DriverBehaviorEventTypes.LOOK_DOWN:
        case DriverBehaviorEventTypes.MISSING_FACE:
        case DriverBehaviorEventTypes.PHONE_USAGE:
        case DriverBehaviorEventTypes.SMOKING:
        case DriverBehaviorEventTypes.YAWN:
          return renderCommonFormat(event.data as CommonTrackingLocationUpdateData, event.feature, event.eventTypeId, event.featureTypeId);
        case DriverBehaviorEventTypes.OTHER:
          return <OtherDriverBehaviorFormat data={event.data as CommonTrackingLocationUpdateData & { type: string }} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.DURESS_V0:
      switch (event.eventTypeId) {
        case DuressEventTypes.DURESS_BUTTON_ACTIVATED:
          return <DuressButtonActivatedFormat data={event.data as DuressButtonActivated} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.EVENT_RECORD_V0:
      switch (event.eventTypeId) {
        case EventRecordEventTypes.OCCURRENCE:
          return renderCommonFormat(event.data as CommonTrackingLocationUpdateData, event.feature, event.eventTypeId, event.featureTypeId);
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.INERTIA_V0:
      switch (event.eventTypeId) {
        case InertiaEventTypes.DANGEROUS_CORNERING:
        case InertiaEventTypes.RAPID_ACCELERATION:
        case InertiaEventTypes.RAPID_DECELERATION:
          return <InertiaFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        case InertiaEventTypes.ROLLOVER:
          return <RolloverFormat data={event.data as Rollover} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.INSTALLATION_V0:
      switch (event.eventTypeId) {
        case InstallationEventTypes.DEVICE_INSTALLED:
        case InstallationEventTypes.DEVICE_UNINSTALLED:
          return <DeviceFormat data={event.data as DeviceInstalled | DeviceUninstalled} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.PERSON_V0:
      switch (event.eventTypeId) {
        case PersonEventTypes.PERSON_CHANGED:
          return <PersonChangedFormat data={event.data as PersonChanged} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.SIMPLE_ACTIVITY_V0:
      switch (event.eventTypeId) {
        case SimpleActivityEventTypes.START:
        case SimpleActivityEventTypes.FINISH:
          return renderCommonFormat(event.data as CommonTrackingLocationUpdateData, event.feature, event.eventTypeId, event.featureTypeId);
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.TRACKING_V0:
      switch (event.eventTypeId) {
        case TrackingEventTypes.LOCATION_UPDATE:
        case TrackingEventTypes.MOTION_START:
        case TrackingEventTypes.MOTION_FINISH:
        case TrackingEventTypes.POWER_ON:
        case TrackingEventTypes.POWER_OFF:
          return renderCommonFormat(event.data as CommonTrackingLocationUpdateData, event.feature, event.eventTypeId, event.featureTypeId);
        case TrackingEventTypes.SPEEDING_START:
        case TrackingEventTypes.SPEEDING_FINISH:
          return <SpeedingFormat data={event.data as SpeedingStart | SpeedingFinish} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        case TrackingEventTypes.SUMMARY:
          return <TrackingSummaryFormat data={event.data as TrackingSummary} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.VEHICLE_SEAT_V0:
      switch (event.eventTypeId) {
        case VehicleSeatEventTypes.MOVEMENT_WITHOUT_RESTRAINT_START:
        case VehicleSeatEventTypes.MOVEMENT_WITHOUT_RESTRAINT_FINISH:
          return renderCommonFormat(event.data as CommonTrackingLocationUpdateData, event.feature, event.eventTypeId, event.featureTypeId);
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    case FeatureTypes.FAULT_V0:
      switch (event.eventTypeId) {
        case FaultEventTypes.UPDATE: {
          const data = event.data as FaultUpdate;
          if (event.feature === 'fault/dtc' && data.type === 'J1939') {
            return <J1939Format data={data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} thingId={event.thingId} />;
          }
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
        }
        default:
          return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
      }
    default:
      return <NoEventFormat data={event.data} feature={event.feature} eventTypeId={event.eventTypeId} featureTypeId={event.featureTypeId} />;
  }
};
