import { AccessToken, AccessTokenPayload, AnyObject, AxiosOptions, BaseTokenProvider, BaseTokenProviderOptions, consoleBaseLogger, getOpenIdConfigurationUrl, HEADER_ETHINGS_ORIGIN, MaybeRawAccessToken, Token, TokenPayload } from '@eagle/common';
import { Account, AccountType, PublicAuthConfig, User } from '@eagle/core-data-types';
import { EthingsRestClient, getRestClientMetric } from '@eagle/ethings-rest-client';
import { captureException } from '@sentry/react';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { BroadcastChannel } from 'broadcast-channel';
import { jsonDateParser } from 'json-date-parser';
import { omit } from 'lodash';
import queryString from 'query-string';
import { Nullable, Undefinable } from '../types';
import { loadConfig } from '../util';
import { CodedError, isAxiosError } from '../util/error';
import { AuthenticatedState, AuthenticationState, AuthStatus, DomainCustomConfig, NotAuthenticatedState, OpenIdConfiguration, SessionStorageKeys, WebOpenIdConfiguration } from './types';
import { queryToObject } from './util';

export interface Base64TokenSet {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

const TOKEN_PARTS = 3;
const TOKEN_BODY_INDEX = 1;

export const decodeToken = <T extends Token>(raw: string): Nullable<T> => {
  if (!raw) return null;
  const payload = decomposeJwt(raw);
  if (!payload) return null;
  return { payload, raw, expires: payload.exp * 1000 } as unknown as T;
};

export const decomposeJwt = <T extends TokenPayload>(token: string): Nullable<(T & { exp: number })> => {
  if (!token || token.split('.').length !== TOKEN_PARTS) {
    return null;
  }

  const base64Url = token.split('.')[TOKEN_BODY_INDEX];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  try {
    const decoded = JSON.parse(decodeURIComponent(window.atob(base64))) as Nullable<{ exp: number }>;

    // TODO use validata checks from @eagle/common or jose@3.x
    return typeof decoded === 'object' && decoded && 'exp' in decoded && typeof decoded.exp === 'number'
      ? decoded as unknown as T & { exp: number }
      : null;
  } catch (error) {
    captureException(error);
    return null;
  }
};

export interface ClientTokenProviderOptions extends BaseTokenProviderOptions {
  setAuth: (authenticationState?: AuthenticationState) => void;
}

export class ClientTokenProvider extends BaseTokenProvider {
  protected authSync: BroadcastChannel;
  protected remoteAuthMessageHandler?: (this: BroadcastChannel, ev: MaybeRawAccessToken) => any;
  private previousAuthState: Undefinable<AuthenticatedState>;

  constructor(protected options: ClientTokenProviderOptions) {
    super(options);
    this.authSync = new BroadcastChannel('auth-sync');
    this.watchRemoteTabAuthChanges();
  }

  private compareAuthStates = (authState1: AuthenticatedState, authState2: AuthenticatedState): boolean => {
    return JSON.stringify(omit(authState1, ['timestamp'])) === JSON.stringify(omit(authState2, ['timestamp']));
  };

  protected watchRemoteTabAuthChanges(): void {
    this.remoteAuthMessageHandler = (event: MaybeRawAccessToken): void => {
      if (event.accessToken) {
        const payload = this.options.decodeToken<AccessTokenPayload>(event.accessToken);
        this.accessToken = {
          raw: event.accessToken,
          expires: payload.exp,
          payload,
        };
      }
      this.updateLocalAuth(event);
    };
    // Auth set remotely, e.g. another tab
    this.authSync.addEventListener('message', this.remoteAuthMessageHandler);
  }

  public cleanup(): void {
    if (!this.remoteAuthMessageHandler) return;

    this.authSync.removeEventListener('message', this.remoteAuthMessageHandler);
  }

  public errorHandler(error: Error): void {
    if (isAxiosError(error)) {
      captureException(error);
      switch (error.response?.status) {
        case 400: {
          const { data } = error.response as { data: AnyObject };
          if ('error_description' in data) {
            this.options.setAuth({
              failure: {
                error: String(data.error ?? 'unauthorized'),
                errorDescription: String(data.error_description),
              },
              status: AuthStatus.AUTHENTICATION_FAILED,
            });
          }
          return;
        }
        case 401: {
          this.options.setAuth({
            failure: {
              error: 'unauthorized',
              errorDescription: error.response.statusText,
            },
            status: AuthStatus.AUTHENTICATION_FAILED,
          });
          return;
        }
        case 403: {
          this.options.setAuth({
            failure: {
              error: 'forbidden',
              errorDescription: error.response.statusText,
            },
            status: AuthStatus.AUTHENTICATION_FAILED,
          });
          return;
        }
      }
    } else if (!axios.isCancel(error)) {
      captureException(error);
    }
    this.options.setAuth({
      error,
      status: AuthStatus.CONFIG_ERROR,
    });
    console.error(error);
  }

  public getAccessToken(): Undefinable<AccessToken> {
    return this.accessToken;
  }

  protected updateLocalAuth(data: MaybeRawAccessToken): void {
    this.getAuthenticationState(data?.accessToken ?? null)
      .then((auth) => {
        this.options.setAuth(auth);
      })
      .catch((err) => {
        console.error(err);
        captureException(err);
        this.setAuthToNotAuthenticated(new CodedError('Unable to load authorization', 'error-getting-auth'));
      });
  }

  private updateRemoteAndLocalAuth(accessToken: Nullable<string>): void {
    this.authSync.postMessage({ accessToken }).catch(captureException);
    this.updateLocalAuth({ accessToken });
  }

  private updateRemoteAuthSigningOut(): void {
    this.authSync.postMessage(null).catch(captureException);
    this.options.setAuth(undefined);
  }

  protected setAuthToNotAuthenticated(error?: CodedError): NotAuthenticatedState {
    const state: NotAuthenticatedState = { loading: false, status: AuthStatus.NOT_AUTHENTICATED, error };
    this.clearSecureCookiesAndSession();
    this.options.setAuth(state);
    return state;
  }

  protected clearSecureCookiesAndSession(): void {
    const targetAccount = window.sessionStorage.getItem(SessionStorageKeys.SWITCHED_ACCOUNT_ID);

    this.single('clearCookie', async () => {
      return axios.post('/api/v1/token/clear-cookie', { targetAccount });
    }).then().catch(captureException);
    window.sessionStorage.removeItem(SessionStorageKeys.SWITCHED_ACCOUNT_ID);
  }

  protected async getAuthenticationState(rawAccessToken?: Nullable<string>): Promise<AuthenticationState> {
    if (!rawAccessToken) return this.setAuthToNotAuthenticated();

    const accessToken = decodeToken(rawAccessToken) as AccessToken;

    if (!accessToken) return this.setAuthToNotAuthenticated(new CodedError('Access token not present', 'no-access-token'));
    if (typeof accessToken.payload.acc !== 'string') return this.setAuthToNotAuthenticated(new CodedError('Invalid access token', 'invalid-token'));
    if (typeof accessToken.payload.uac !== 'string') return this.setAuthToNotAuthenticated(new CodedError('Missing Base Account ID', 'invalid-base-account-id'));

    try {
      const [accountResponse, accountTypeResponse, userResponse] = await Promise.all([
        axios.get<Account>(`/api/v1/account/${accessToken.payload.acc}`, {
          headers: {
            authorization: `Bearer ${rawAccessToken}`,
          },
        }),
        axios.get<AccountType>('/api/v1/my/account-type', {
          headers: {
            authorization: `Bearer ${rawAccessToken}`,
          },
        }).catch((error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            // Account type not found. Continuing with login process.
            return { data: null };
          }
          throw error;
        }),
        axios.get<User>(`/api/v1/my/user/${accessToken.payload.sub}`, {
          headers: {
            authorization: `Bearer ${rawAccessToken}`,
          },
        }),
      ]);

      const axiosInstance = this.axios();
      const restClient = new EthingsRestClient(
        { axios: axiosInstance, baseUrl: window.location.origin, chunkSize: 1, log: consoleBaseLogger },
        getRestClientMetric(),
      );

      const authState: AuthenticatedState = {
        timestamp: Date.now(),
        axios: axiosInstance,
        restClient,
        loading: false,
        status: AuthStatus.AUTHENTICATED,
        account: accountResponse.data,
        accountType: accountTypeResponse.data ?? undefined,
        user: userResponse.data,
        userInfo: {
          userId: accessToken.payload.sub,
          accountId: accessToken.payload.acc,
          type: accessToken.payload.typ,
          hasRoleFunction: (...anyOfRfn: string[]): boolean => {
            const granted = new Set(Object.keys(accessToken.payload.rfr ?? {}));
            return !!anyOfRfn.find((roleFunction) => granted.has(roleFunction));
          },
          baseAccountId: accessToken.payload.uac,
        },
      };

      if (this.previousAuthState && this.compareAuthStates(this.previousAuthState, authState)) {
        return this.previousAuthState;
      }
      this.previousAuthState = authState;
      return authState;
    } catch (error) {
      console.error('Error during authentication:', error);
      return this.setAuthToNotAuthenticated(new CodedError('Authentication failed', 'auth-failed'));
    }
  }

  public async fetchOidcConfig(auth?: PublicAuthConfig): Promise<Undefinable<OpenIdConfiguration>> {
    if (!auth) return undefined;

    try {
      const configUrl = getOpenIdConfigurationUrl(auth.baseUrl);
      const { data } = await axios.get<WebOpenIdConfiguration>(configUrl);
      // intentionally not awaited
      this.refreshAccessToken().catch((err: Error) => this.errorHandler(err));
      return {
        ...auth,
        authUrl: data.authorization_endpoint,
        signOutUrl: data.end_session_endpoint,
        tokenEndpoint: data.token_endpoint,
        xEthingsAuthenticationProvider: data.x_ethings_authentication_provider,
      };
    } catch (error) {
      this.errorHandler(error as Error);
    }
  }

  private getCode(): { code: string; stateEncoded: string | (string | null)[] | null } | null {
    const { error, error_description: errorDescription, code, state: stateEncoded } = queryString.parse(document.location.hash.replace(/^[#/]*/, ''));
    if (error) {
      this.options.logger?.error(error, errorDescription, document.location.hash);
      this.options.setAuth({
        status: AuthStatus.AUTHENTICATION_FAILED,
        failure: {
          error: error
            ? Array.isArray(error)
              ? error[0] ?? 'unknown'
              : error
            : 'unknown',
          errorDescription: errorDescription
            ? Array.isArray(errorDescription)
              ? errorDescription[0] ?? 'Unknown error'
              : errorDescription
            : 'Unknown error',
        },
      });
      document.location.hash = '';
      throw new Error('Login failed with error');
    }

    if (!code || Array.isArray(code)) return null;

    return { code, stateEncoded };
  }

  public axios(axiosOptions?: AxiosOptions): AxiosInstance {
    return super.axios({
      config: {
        ...axiosOptions?.config,
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        transformResponse: [function transformResponse(this: AxiosRequestConfig, response: unknown) {
          try {
            return JSON.parse(response as string, jsonDateParser) as Record<string, unknown>;
          }
          catch (e) {
            captureException(e, { extra: { response, url: this.url, params: this.params } });
            throw e;
          }
        }],
      },
    });
  }

  protected refresh(_issuer?: string): Promise<void> {
    return this.singleTokenRequest(
      'refresh',
      BaseTokenProvider.refreshEndpoint,
      {},
      {}, // refreshToken travels as a safe cookie
      'Auth token refreshed',
    ).then(() => {
      if (!this.accessToken) {
        this.setAuthToNotAuthenticated();
      }
    });
  }

  protected create(issuer?: string): Promise<void> {
    const codeResult = this.getCode();
    if (!codeResult) return this.refresh(issuer);

    return this.singleTokenRequest(
      'create',
      '/api/v1/user/authorize',
      {
        code: codeResult.code,
        codeVerifier: window.localStorage.getItem('code-verifier'),
      },
      {},
      'Client token created',
    ).finally(() => {
      document.location.hash = queryToObject(codeResult.stateEncoded)?.hash ?? '/';
    });
  }

  protected saveRefreshToken(_raw: string): void {
  }

  protected saveAccessToken(raw: string): void {
    if (!raw) {
      console.warn('Type mismatch', 'CTP saveAccessToken', raw);
      return;
    }
    const payload = this.options.decodeToken<AccessTokenPayload>(raw);
    this.accessToken = {
      raw,
      expires: payload.exp,
      payload,
    };
    this.updateRemoteAndLocalAuth(raw);
  }

  public async signOut(url?: string, signInParam?: string): Promise<void> {
    const { data: { idToken } } = await this.single('signOut', async () => {
      return axios.post<{ idToken: string }>('/api/v1/token/unauthorize');
    });

    this.updateRemoteAndLocalAuth(null);

    if (url) {
      this.updateRemoteAuthSigningOut();
      const location = new URL(document.location.href);
      location.hash = '';
      const signOutUrl = new URL(url);
      signOutUrl.searchParams.append('post_logout_redirect_uri', `${location.toString()}${signInParam ? `?${signInParam}=true` : ''}`);
      if (idToken) {
        signOutUrl.searchParams.append('id_token_hint', idToken);
      }
      document.location.href = signOutUrl.toString();
    }
  }
}

export interface SwitchedClientTokenProviderOptions extends ClientTokenProviderOptions {
  accountId: string;
}

export class SwitchedClientTokenProvider extends ClientTokenProvider {
  private account: Undefinable<Account>;

  constructor(protected options: SwitchedClientTokenProviderOptions) {
    super(options);
  }

  public setAccessToken(token: string): void {
    this.saveAccessToken(token);
  }

  public axios(axiosOptions?: AxiosOptions): AxiosInstance {
    const axiosInstance = super.axios(axiosOptions);
    axiosInstance.interceptors.request.use((options) => {
      if (this.account) {
        options.headers = {
          ...options.headers,
          [HEADER_ETHINGS_ORIGIN]: `${window.location.protocol}//${this.account.homeDomain}`,
        };
      }
      return options;
    });
    return axiosInstance;
  }

  protected refresh(_issuer?: string): Promise<void> {
    return this.singleTokenRequest(
      'refresh',
      `/api/v1/token/user/target-account/${this.options.accountId}/refresh`,
      {},
      {}, // refreshToken travels as a safe cookie
      'Auth token refreshed',
    ).then(() => {
      if (this.accessToken) return;

      this.setAuthToNotAuthenticated();
    });
  }

  protected create(issuer?: string): Promise<void> {
    return this.refresh(issuer);
  }

  protected saveAccessToken(raw: string): void {
    if (!raw) {
      console.warn('Type mismatch', 'SCTP saveAccessToken');
      return;
    }
    const payload = this.options.decodeToken<AccessTokenPayload>(raw);
    this.accessToken = {
      raw,
      expires: payload.exp,
      payload,
    };
    this.updateLocalAuth({ accessToken: raw });
  }

  protected async getAuthenticationState(rawAccessToken?: Nullable<string>): Promise<AuthenticationState> {
    const authenticationState = await super.getAuthenticationState(rawAccessToken);
    if (authenticationState.status !== AuthStatus.AUTHENTICATED) return authenticationState;

    this.account = authenticationState.account;

    let config: Undefinable<Partial<DomainCustomConfig>>;
    try {
      config = await loadConfig(true, this.account.homeDomain);
    } catch (e) {
      console.error(e);
    }

    return {
      ...authenticationState,
      switchedConfig: config,
      switchedTokenProvider: this,
    };
  }

  protected watchRemoteTabAuthChanges(): void {
    this.remoteAuthMessageHandler = (event: any): void => {
      // Watch signOut changes only
      if (event === null) this.setAuthToNotAuthenticated();
    };
    // Auth set remotely, e.g. another tab
    this.authSync.addEventListener('message', this.remoteAuthMessageHandler);
  }
}
